.Stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.server-grafik {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1800px;
}

/*Mobile Media Query*/
@media only screen and (max-width: 900px) {
  .Stats {
    padding: 20px;
  }

  .server-grafik {
    display: none;
  }

  .stat-grid {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 901px) {
  .Stats {
    padding: 20px;
  }

  .stat-grid {
    display: none;
  }
}

.stat-card {
  background-color: #444444;
  display: flex;
  padding: 29px;

  hyphens: manual;
  width: 250px;
  min-height: 120px;
  text-align: center;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .stat-Header {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .stat-highlight {
    color: #ff3333;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .body {
    color: #fff;
    text-align: center;
    /*font-size: 12px;*/
    font-weight: 400;
    line-height: 150%;
    margin-top: 10px;
  }
}
