* {
  font-family: "Be Vietnam Pro", Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.transparent {
  color: transparent;
  font-size: 10px;
  height: 0;
  width: 0;
}

button {
  background-color: transparent;
  border: none;
}

header {
  text-align: left;
}

main{
  margin-top: 120px;

  &.has-margin-top-small {
    margin-top: 110px;
  }

  &.has-margin-top-large {
    margin-top: 150px;
  }
}

body {
  overscroll-behavior: none; /* Verhindert das "bouncy scrolling" */
  background-color: var(--c-base);
  color: white;

  font-family: "Be Vietnam Pro";
  max-width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1921px) {
  body {
    max-width: var(--max-width);
    margin: 0 auto 0 auto;
  }
}

p {
  color: #ffffff;
  font-family: "Be Vietnam Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

h1 {
  font-size: 31.5px;
}

h2 {
  font-size: 25px;
  font-weight: 800;
}

h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

.heading-underlined {
  text-decoration-line: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: var(--c-red);
  text-underline-offset: 6px;
  border: 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 80px;
}

@media only screen and (max-width: 500px) {
  .heading-underlined {
    font-size: 22px;
    text-decoration-thickness: 2px;
    margin-bottom: 30px;
  }
}

.link {
  color: var(--c-blue);
  text-decoration: none;

  &.is-green {
    color: #48a61c;
  }

  &.is-insideText {
   margin: "0px 4px"
  }
}



.link:hover {
  text-decoration: underline;
}

.SVGLogo {
  width: 250px;
  max-width: 60%;
}

figcaption {
  p {
    color: var(--c-lightest);
    font-style: italic;
  }
  a {
    text-decoration: none;
  }
}

@media only screen and (max-width: 500px) {
  p {
    font-size: 15px;
    line-height: 170%;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 17px;
  }

  html,
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
}
