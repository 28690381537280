.contact-button-small {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 800;
  padding: 10px 10px;
  background-color: var(--c-green);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--c-green-dark);
  }

  @media screen and (min-width: 601px) {
    display: none;
  }
}

.contact-button-large-container {
  display: flex;
  margin-top: 30px;
  justify-content: center;

  @media screen and (max-width: 601px) {
    margin-top: 20px;
  }
}

.contact-button-large {
  color: white;
  background-color: var(--c-green);
  padding: 20px 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-self: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 601px) {
    padding: 10px 20px;
  }
}

.contact-button-row {
  margin-top: 15px;
  display: flex;
  justify-content: left;
}

.contact-button-medium{
  background-color: var(--c-green);
  padding: 10px 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-self: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: var(--c-green-dark);
  }

  &.is-outlined {
    background-color: transparent;
    color: var(--c-green);
    border: 1px solid white;

    &:hover {
      color: white;
    }
  }

  &.has-icon {
    padding: 10px;

    img {
      padding-right: 0;
      padding-left: 10px;
      width: 21px;
      transition: all 0.3s ease;

    }

    &:hover{
      img {
        /*animated icon moves to the right on hover*/
        margin-left: 10px;
      }
    }
  }
}