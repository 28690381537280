/*-----------------------------------------------------------------left&right-----------------------------------------------------------------*/

.lr-margin {
    margin-left:    100px;
    margin-right:   100px;
}

@media only screen and (max-width: 767px) {
    .lr-margin {
        margin-left:    20px;
        margin-right:   20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .lr-margin {
        margin-left:    50px;
        margin-right:   50px;
        }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .lr-margin {
        margin-left:    80px;
        margin-right:   80px;
        }
}

@media only screen and (min-width: 1280px) {
    .lr-margin {
        margin-left:    100px;
        margin-right:   100px;
    }
}

/*-----------------------------------------------------------------left-----------------------------------------------------------------*/
.left-margin {
    margin-left: 100px;
}

@media only screen and (max-width: 767px) {
    .left-margin {
        margin-left: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .left-margin {
        margin-left: 50px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .left-margin {
        margin-left: 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .left-margin {
        margin-left: 100px;
    }
}

/*-----------------------------------------------------------------right-----------------------------------------------------------------*/
.right-margin {
    margin-left: 100px;
}

@media only screen and (max-width: 767px) {
    .right-margin {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .right-margin {
        margin-right: 50px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .right-margin {
        margin-right: 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .right-margin {
        margin-right: 100px;
    }
}

/*-----------------------------------------------------------------top&bottom-----------------------------------------------------------------*/

.tb-margin {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 767px) {
    .tb-margin {
        margin-top: 10px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .tb-margin {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .tb-margin {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 1280px) {
    .tb-margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}


/*-----------------------------------------------------------------top-----------------------------------------------------------------*/

.t-margin {
    margin-top: 50px;
}

@media only screen and (max-width: 767px) {
    .t-margin {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .t-margin {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .t-margin {
        margin-top: 40px;
    }
}

@media only screen and (min-width: 1280px) {
    .t-margin {
        margin-top: 50px;
    }
}