.Imageblock {
  width: 100%;
  max-height: 810px;
  max-width: 810px;
  overflow: hidden;
}

.catchphrase-block .row {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  margin-top: -50px;
}

@media only screen and (max-width: 1023px) {
  .catchphrase-block .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .catchphrase-block .row .Imageblock {
    display: none;
  }
}

.Imageblock-mobile {
  object-fit: contain;

  max-height: 600px;
  margin-bottom: -50px;

  max-width: 100%;
  filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, .85));
}

@media only screen and (min-width: 1022px) {
  .Imageblock-mobile {
    display: none;
  }
}

.landingText {
  margin-top: 5px;
}

@media only screen and (max-width: 500px) {
  .animated-H1 {
    font-size: 22px;
    font-weight: 750;
  }

  .inner-headings {
    font-size: 22px;
    font-weight: 750;
    margin: 3px;
  }

  .outer-headings {
    height: 40px;
  }

  .landingText {
    margin-top: 10px;
  }
}

.textblock {
  width: 100%;
  height: fit-content;
  margin-top: 50px;
  padding-top: 5%;
  margin-bottom: 50px;
  max-width: 1250px;
}
