.kontakt-wrapper {
  display: flex;
  max-width: 900px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

  h2 {
    color: white;
    margin-bottom: 10px;
  }

  .kontakt-info {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    flex: 20%;
    background-color: var(--c-lightest);
    padding: var(--padding-form);

    h2 {
      color: white;
      font-weight: normal;
      margin-bottom: 7px;
    }

    p:not(:last-child){
      padding-bottom: 15px;
    }
  }
}

@media screen and (max-width: 710px) {
  .kontakt-wrapper {
    flex-direction: column;
  }
  
}

:root {
  --padding-form: 30px;
  --padding-form-mobile: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 50%;
  background-color: #444444;
  align-items: start;
  padding: var(--padding-form);

  @media screen and (max-width: 450px) {
    padding: var(--padding-form-mobile);
  }

  label, p {
    margin-bottom: 5px;
    font-size: small;
  }

  .input-row {
    width: 100%;
    display: flex;
    gap: 15px;

    input {
      width: 100%;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      gap: 0px;
    }
  }

  button {
    padding: 0 10px;
    background-color: var(--c-green);
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    justify-content: center;

    &:hover {
      padding: 20px;
      font-weight: bold;
    }
  }

  .status-message{
    font-size: smaller;
    align-self: center;

    @media screen and (max-width: 450px) {
      margin-top: 10px;
    }
  }

  .status-message-success {
    color: #d0fd96;
  }

  .status-message-error {
    color: #ff7f7f;
  }
}

.form input,
.form textarea {
  margin-bottom: 15px;
  min-height: 40px;
  padding-inline: 10px;
  font-size: 15px;
  outline: none;
  border: 0;
  background-color: #606060;
  color: white;
  /*interessant: 100% mit padding*/
  width: 100%; /* Hier wird die Breite auf 100% gesetzt */
  box-sizing: border-box; /* Stellt sicher, dass Padding und Border in der Breite enthalten sind */
}

.form textarea {
  resize: vertical;
  min-height: 250px;
  padding-block: 10px;
}

.checkbox-row {
  display: flex;
  align-items: center;

  @media screen and (max-width: 450px) {
    margin-top: 15px;
  }
}

.checkbox-container {
  cursor: pointer;
}

.checkbox-container input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.checkmark {
  height: 18px;
  width: 18px;
  min-width: 18px;
  background-color: #ffffff;
  border-radius: 0px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
  position: relative;
}

.checkbox-container input:checked + .checkmark {
  background-color: var(--c-green);
}

.checkmark:after {
  content: '';
  width: 9px;
  height: 5px;
  margin-bottom: 4px;
  border: solid #ffffff;
  border-width: 2px 2px 0px 0px;
  display: none;
  transform: rotate(135deg);
  position: absolute;
}

.checkbox-container input:checked + .checkmark:after {
  display: block;
}



.img-karte {
  width: 900px;
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  transition: all 0.2s;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover; /* Stellt sicher, dass das Bild das gesamte Element ausfüllt */
    transition: all 0.2s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}