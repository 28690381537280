.TeamHighFive {
    max-height: 850px;
    max-width: 90%;
    align-self: flex-end;
}

.ueberUns-block {
    margin-right: 0px;
    margin-bottom: 0px;

    max-width: 800px;
}

.ueberUns-row {
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    gap: 10%;
}


@media only screen and (max-width: 1300px) {
    .ueberUns-row {
        margin-top: 50px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: right;
    }
}

@media only screen and (max-width: 766px) {
    .ueberUns-block {
        margin-right: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .ueberUns-block {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .ueberUns-block {
        margin-right: 50px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .ueberUns-block {
        margin-right: 80px;
    }
}

@media only screen and (min-width: 1280px) {
    .ueberUns-block {
        margin-right: 100px;
    }
}