:root {
    --c-base: #202020;
    --c-lighter: #444444;
    --c-lightest: #606060;
    --c-green: #378910;
    --c-green-dark: #29640d;
    --c-red: #F33;
    --c-blue: #429FFF;

    --max-width: 1920px;
}