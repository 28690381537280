.error-main {
  display: flex;
  align-items: center;
  margin: 150px;

  flex-direction: row;
  
  div {
    margin-top: 40px;
    margin-left: 10%;
  }
}

@media only screen and (max-width: 1400px) {
  .error-main {
    flex-direction: column;
  }
}

.error-img {
  width: 30%;
  min-width: 280px;
  max-width: 350px;
}
