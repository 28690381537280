.cardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(413px, 413px));
  gap: 30px;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

/* media query for half screen */
@media only screen and (max-width: 960px) {
  .cardGrid {
    grid-template-columns: repeat(auto-fit, minmax(413px, 1fr));
  }
}

.cardGrid .card:hover {
  margin-right: 60px;
}

.card-wrapper{
  /* Fix für 1px fehler unten*/
  overflow: hidden;
}

.card {
  width: 410px;
  margin-left: 3px;
  height: 360px;
  display: flex;
  flex-direction: column;
  background-color: var(--c-lighter);
  position: relative;
  transition: margin-right 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.301);
  
  .header {
    width: 100%;
    height: 72px;
    background-color: var(--c-lightest);
    align-items: center;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;

    img{
      display: none;
    }
  }

  .body {
    width: 100%;
    height: 288px;
    padding: 20px;
    box-sizing: border-box;
  }

  .after-slideout {
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 50px;
    left: 100%;
    background-color: var(--c-green);
    transition: transform 0.3s;
    transform: translateX(-50px);
    z-index: -1;
  }

  &.is-active {
    margin-right: 60px;
    & .after-slideout {
      transform: translateX(0px);
    }
  }
}

/*-----------------------------------------Card Grid Switch-----------------------------------------*/

@media only screen and (min-width: 601px) {
  #card-grid  {
    display: grid;
   }
 
   #card-grid-mobile {
     display: none;
   }
}

@media only screen and (max-width: 600px) {

  #card-grid  {
   display: none;
  }

  #card-grid-mobile {
    display: grid;
  }
}

#infoBox{
  grid-column: 1 / -1;
  background-color: var( --c-lighter);
  width: 100%;
  height: fit-content;

  #textContainer{
    margin: 5%;

    p{
      padding-bottom: 30px;
      line-height: 180%;
    }

    h4{
      padding-bottom: 8px;
    }
  }

  #iconContainer{
    display: inline-flex;
    align-items: center;
    margin-top: 5%;
    margin-left: 5%;

    h3{
    font-size: 25px;
    }

    gap: 20px;

    img{
      width: 50px;
      filter: drop-shadow(0 0 10px #378910);
    }
  }
}

/*-----------------------------------------Mobile Service Card-----------------------------------------*/

.mobile-service-card {
  width: 345px;
  height: fit-content;
  position: relative;
  background-color: var(--c-lighter);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.301);
  padding-bottom: 10px;
  transition: max-height 0.3s ease;
  max-height: 350px;
  min-height: 175px;

  &.is-expanded {
    max-height: 10000px;
  }

  .header {
    width: 100%;
    height: 72px;
    background-color: var(--c-lightest);
    align-items: center;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .body {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: fit-content;
    margin-bottom: 20px;

    h4{
      margin-bottom: 3px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

.fade-out {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 40px;
  transition: height 0.3s ease;
  background: linear-gradient(
    to bottom,
    #ffffff00,
    var(--c-lighter) 100%
  );

  &.is-expanded {
    height: 0px;
    width: 0px;
  }
}


.fade-out-2 {
  width: 100%;
  height: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    to top,
    var(--c-lighter) 20px,
    transparent 100%
  );
}

.mobile-mehr-button {
  border: none;
  position: absolute;
  bottom: 0;
  padding-left: 20px;
  width: 100%;
  height: 60px;
  background-color: var(--c-lighter);
  align-items: center;
  cursor: pointer;
  display: flex;

  img {
    margin-left: 7px;
    rotate: 90deg;
    transition: transform 0.4s ease;
  }

  &.is-expanded {
    img {
      transform: rotate(-180deg);
    }
  }
}