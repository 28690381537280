
header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  z-index: 1000;
  /*padding-right: 20px; */ /* Abstand entfernt, dafür lr margin bei nav eingefügt */
  max-width: var(--max-width);

  &.has-margin {
    margin-bottom: 30px;
  }

 }

.logo a img {
  height: 50px;
  width: fit-content;
  transition: height 0.3s ease;
}

#logo-wrapper {
  transition: margin 0.3s ease-in-out; /* Übergang für Margen-Änderungen */
  padding-left: 0px;
}

.header-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

nav ul li {
  margin-left: 20px;
  display: inline;
  display: none;
}

.hamburger-button {
  display: none;
  img{
    display: none;
  }
}

/* hamburger menu button und ul Umschaltung*/
@media only screen and (min-width: 601px) {
  nav ul li {
    margin-left: 20px;
    display: inline;
  }

  .hamburger-button {
    display: none;
    img{
      display: none;
    }
  }  
}

@media only screen and (max-width: 600px) {

  nav ul li {
    margin-left: 20px;
    display: none;
  }

  .hamburger-button {
    display: block;
    img{
      display: block;
    }
  }  
}

nav ul li a {
  color: white;
  text-decoration: none;
}

/* Side Menu -------------------------------------------------------------------------------*/
.side-menu {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--c-lightest);
  overflow-x: hidden;
  transition: 0.3s;
  padding-top: 60px;
  z-index: 999;
}

.side-menu a {
  padding: 10px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

.side-menu .close-btn {
  position: absolute;
  top: 20px;
  right: 25px;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: none;
}