footer {
    background-color: var(--c-lighter);
    width: 100%;
    display: flex;
    align-items: center;

    &.no-margin {
        margin-top: 0;
    }
}

.inner-row {
    padding: 0;
    margin: 0;
    display: flex; 
    justify-content: flex-start;
}

.outer-row {
    width: 100%;
    margin-left: 100px;
    margin-right: 100px;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: space-between; 

    display: inline-flex;
    align-items: center;
}

/*Mobile Media Query*/
@media only screen and (max-width: 768px) {
    .outer-row {
        flex-direction: column;
        align-items: center; 
        gap: 20px;
    }

    .inner-row {
        flex-direction: column;
        
    }
}

.footer-List {
    list-style-type: none;
    margin: 0px 100px 0px 0px;
}

@media only screen and (max-width: 768px) {
    .footer-List {
        list-style-type: none;
        margin: 0px;
    }
}

.footer-button {
    color: white;
    font-size: 16px; 
    line-height: 50px; 
    text-decoration: none; 
}

.info-container {
    margin-top: 0px;
    margin-bottom: 0px;
}