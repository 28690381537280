.headings-wrapper {
  height: auto;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  width: 695px;
}

.animated-Headline {
  font-size: 40px;
  color: #ffffff;
  font-weight: 900;
  line-height: normal;
  line-height: 55px;
}

.typewriter {
  overflow: hidden;
  white-space: nowrap; /* Verhindert Zeilenumbrüche */
  margin: 0 auto; /* Zentriert die Animation */
  margin-left: 10px;
  color: var(--c-green);
}

.active::after {
  content: '|';
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

@media only screen and (max-width: 500px) {
  .animated-Headline {
    font-size: 22px;
    font-weight: 750;
  }

  .typewriter {
    font-size: 22px;
    font-weight: 750;
    margin: 3px ;
  }

  .headings-wrapper {
    height: 40px;
  }
}